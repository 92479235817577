<template>
  <a-modal v-model="visible" :title="title" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
    <a-form-model ref="form" :model="dataForm" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item prop="stock_in_quantity" label="入库数量">
        <a-input-number v-model="dataForm.stock_in_quantity" :max="item.quantity_produced" style="width: 100%" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { productionOrderStockIn } from "@/api/production";
import { warehousesOption } from "@/api/option";

export default {
  components: {},
  props: ["visible", "title", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules: {
        stock_in_quantity: [{ required: true, message: "请输入入库数量", trigger: "change" }],
      },
      loading: false,
      dataForm: {},
      warehouseItems: [],
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          productionOrderStockIn({ id: this.item.id, ...this.dataForm })
            .then((data) => {
              this.$message.success("入库成功");
              this.$emit("update", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.dataForm = {stock_in_quantity: this.item.quantity_produced};
        warehousesOption({ page_size: 999999, is_active: true }).then((data) => {
          this.warehouseItems = data.results;
        });
      }
    },
  },
};
</script>

<style scoped></style>
